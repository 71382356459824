.arrow_left {
  height: 18px;
  width: 18px;
}

.main-menu {
  padding: 28px 0;
  background-color: #fff;
}

.main-menu {
  a {
    color: $text-gray-900 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
  }
}

.main-menu {
  a {
    img.back_arrow {
      margin-right: 8px;
    }
  }
}
