.forgot_password_card {
  padding: 46px;
  border-radius: 24px 12px 24px 12px;
  border: 1px solid $text-gray-200;
  box-shadow: 0px 4px 8px -2px #1018281a;

  h1 {
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: $text-gray-900;
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    color: $text-gray-500-1;

    &.signup-link {
      margin: 0;
      margin-top: 16px;
      color: $text-gray-500;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 18px;

      a {
        &.signup-here {
          color: $theme-blue-700;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-left: 16px;
        }
      }
    }

    &.need_help {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text-gray-500-1;
      text-align: center;
      border-top: 1px solid $text-gray-300;
      padding-top: 18px;
    }

    &.recovery_email {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 767px) {
  .forgot_password_card {
    padding: 24px;
  }
}
