$text-gray-600: #475467;
$text-gray-900: #1d2939;
$text-gray-200: #eaecf0;
$text-gray-300: #d0d5dd;
$text-gray-700: #344054;
$text-gray-500: #344054;
$text-gray-500-1: #5d667b;
$text-gray-50: #f9fafb;

$btn-gradient-purple: #8a75fe;
$btn-gradient-blue: #3a58ef;

$theme-blue-700: #3e2fb7;
$theme-blue-600: #5849d0;
$theme-blue-50: #ebeefd;

$theme-purple-500: #7162ea;
$theme-purple-50: #f3f1fd;

$white: #ffffff;

$error-red-500: #f04438;
$error-red-300: #fda29b;

$shadows-sm-6perc: #1018280f;
$shadows-sm-10perc: #1018281a;
$shadow-sm-input: #e8e7f4;
$shadow-sm-error-input: #feedeb;

$success-green-500: #12b76a;
