.subscribed-modal {
  .modal-content {
    padding: 46px;
    border-radius: 24px 12px;
    border: 1px solid $text-gray-200;
    background: $white;
  }

  img {
    margin-bottom: 32px;
  }

  h4 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: $text-gray-900;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 32px;

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: $text-gray-500-1;
    }
  }
}
