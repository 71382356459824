.footer {
  padding: 16px 0px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-gray-500-1 !important;
  text-decoration: none;
}

.under-line {
  color: $text-gray-900 !important;
  font-weight: 500;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
    .support-mail {
      text-align: center !important;
    }
  }
}
