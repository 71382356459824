// :root {
//   --text_gray_600: #475467;
//   --text_gray_900: #1D2939;
//   --text_gray_200: #EAECF0;
//   --text_gray_300: #D0D5DD;
//   --text_gray_700: #344054;
//   --text_gray_500: #344054;
//   --text_gray_500_1: #5D667B;
//   --text_gray_50: #F9FAFB;

//   --btn_gradient_purple: #8A75FE;
//   --btn_gradient_blue: #3A58EF;

//   --theme_blue_700: #3E2FB7;
//   --theme_blue_600: #5849d0;
//   --theme_blue_50: #EBEEFD;

//   --white: #FFFFFF;

//   --error_red_500: #f04438;
//   --error_red_300: #FDA29B;
// }

body {
  font-family: "Inter";
}

p,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

a {
  text-decoration: none;
}

// ul {
//   margin: 0px;
// }

// li {
//   margin: 0px;
// }

img {
  -webkit-user-drag: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 1) inset !important;
}

.form-check-input:focus,
.form-check-input:active {
  outline: none !important;
  box-shadow: none;
}

// scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #7070707e;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0d6efd;
}
