.login-form {
  background-color: var(--text_gray_50);
  height: 100vh;
  // width: 100vw;
}

.login-main {
  background-image: url(../../Imgs/login_bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover; 
  padding: 40px 0px;
  border-bottom: 1px solid $text-gray-200;
  height: calc(100% - 136px);
  width: 100%;
  display: flex;
  align-items: center;
}

.signup-main {
  background-image: url(../../Imgs/login_bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 40px 0px;

  @media (max-width: 820px) {
    .row {
      .col-md-6 {
        width: 100%;
      }
    }
  }
}

@media (max-width: 820px) {
  .logo {
    width: 122px;
    height: 102px;
    margin-bottom: 46px;
  }
}
