.honest-pricing-modal {
  .modal-content {
    border-radius: 24px 12px;
  }
}

.honest-content {
  .modal-body {
    padding: 56px 30px;
  }
}

.honest-main {
  .title {
    color: $text-gray-900;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 12px;
  }
}

.honest-main {
  p {
    color: $text-gray-500-1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.honest-main {
  ul {
    padding: 0px;
    margin: 0;
  }
}

.honest-main {
  ul {
    li {
      color: $text-gray-500-1;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      list-style: none;
      margin-bottom: 12px;
    }
  }
}

.honest-main {
  ul {
    li {
      img {
        margin-right: 6px;
      }
    }
  }
}

.honest-content {
  .modal-header {
    position: absolute;
    right: 0;
    border: 0;
    background-color: #ebeefd;
    border-radius: 6px 12px 6px 76px;
    z-index: 999;
  }
}

.honest-content {
  .modal-header {
    button.btn-close {
      font-size: 12px;
      margin-top: -11px;
    }
  }
}

.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none;
}
