%common-divider {
  flex: 1;
  width: 100%;
  height: 0px;
  border: 1px solid $text-gray-200;
}

.signup-form-main {
  background-color: $text-gray-50;
}

.signup-card.card {
  padding: 46px;
  border-radius: 24px 12px 24px 12px;
  background: $white;
  border: 1px solid $text-gray-200;
  box-shadow: 0px 1px 2px 0px $shadows-sm-6perc;
  // box-shadow: 0px 1px 3px 0px #1018281A;
  .heading {
    h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
      color: $text-gray-900;

      @media (max-width: 820px) {
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $text-gray-500-1;

      @media (max-width: 820px) {
        text-align: center;
      }
    }
  }

  .form-switch {
    &.form-check-reverse {
      padding-right: 0px !important;
      align-items: center;

      &.form-check {
        &.form-switch {
          label {
            color: $text-gray-500-1;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .form-check-input {
        margin-left: 16px !important;
        margin-right: 0;
        padding: 8px; //2px
        border-radius: 12px;
        border: 1px solid $text-gray-300;
        color: $white;
        background-color: $white;
        background-image: url(../../Imgs/purple_circle.png);
        box-shadow: 0px 1px 2px 0px $shadows-sm-6perc;
        cursor: pointer;

        &:focus {
          box-shadow: none;
          outline: none;
        }

        &:checked {
          background-color: $theme-purple-500;
          background-image: url(../../Imgs/white_circle.png);
        }
      }

      // .form-check-input:focus {
      //   --bs-form-check-bg: red !important;
      //   --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2386b7fe%27/%3e%3c/svg%3e);
      // }

      .label {
        &.form-check-label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: $text-gray-500-1;
        }
      }

      @media (max-width: 820px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      }
    }
  }

  .terms-privacy-checkbox {
    margin-bottom: 24px;

    .form-check {
      float: inline-start;
      margin-top: -8px;

      .form-check-input[type="checkbox"] {
        width: 20px;
        height: 20px;
        border: 1px solid $success-green-500;
        cursor: pointer;
        &:checked {
          background-color: $success-green-500;
        }
      }

      .form-check-label {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $text-gray-500-1;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
        padding: 2px 4px;
        color: $text-gray-500-1;
      }
    }
  }

  .reCaptcha {
    margin-bottom: 24px;

    // @media (max-width: 768px) {
    //   display: flex;
    //   justify-content: center;
    // }
  }

  .pcc-stripe {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    .stripe-logo {
      margin-left: 6px;
    }

    .divider-y {
      width: 0px;
      height: 100%;
      border: 1px solid $text-gray-200;
      display: inline;
      margin: 0px 12px;
    }
  }

  .total-pricing {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-gray-500-1;
    padding: 12px 16px 12px 16px;
    border-radius: 16px 8px 16px 8px;
    border: 1px dashed $text-gray-300;

    .price {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: $text-gray-900;
      display: inline;
      text-align: end;
    }

    .months {
      display: inline;
      text-align: end;
    }

    .total-txt {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: $text-gray-500-1;
    }
  }

  .signup-form-details {
    .divider-x {
      @extend %common-divider;
    }

    .info-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 12px;
      margin-bottom: 8px;
      padding: 6px 0px;

      .title {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $text-gray-500-1;
        width: fit-content;
      }
    }

    .input-main {
      margin-bottom: 16px;
    }

    .honest-pricing {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      margin-top: 18px;
      cursor: pointer;

      a {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: $text-gray-500-1;
        text-decoration: underline;
      }
    }

    p {
      &.need-help {
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        color: $text-gray-500-1;
        cursor: pointer;
      }
    }

    p {
      &.no-of-seats {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: $text-gray-500-1;
        margin-bottom: 8px;
      }
    }

    .seats-counter {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .input-main {
        margin-bottom: 0px;
        width: 65px;

        .input-field-container {
          margin-bottom: 0px !important;
          padding: 0px;

          input {
            text-align: center;
            border: 1px solid $text-gray-200;
            padding: 8px;
            border-radius: 8px;
            box-shadow: none;
          }
        }
      }

      .seats-counter-btn {
        padding: 8px;
        border-radius: 8px;
        background: $theme-purple-50;
        cursor: pointer;
      }

      img {
        margin-top: -2px;
      }
    }

    .dark {
      &.divider-x {
        @extend %common-divider;
        border: 1px solid $text-gray-300;
        margin: 18px 0px;
      }
    }

    .seats {
      &.divider-x {
        @extend %common-divider;
        margin: 12px 0px;
      }
    }

    .promo-txt {
      display: flex;
      gap: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: $text-gray-500-1;

      img {
        cursor: pointer;
      }
    }

    .promo-price {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: $success-green-500;
    }

    .teams {
      &.total-txt {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: $text-gray-900;
        margin-bottom: 16px;
      }
    }

    .total-price {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: $text-gray-900;
      margin-bottom: 16px;
    }
  }

  p {
    &.seats-price {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: $text-gray-900;
    }
  }

  @media (max-width: 820px) {
    padding: 24px;
  }
}
