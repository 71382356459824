.reset_password_card {
  border-radius: 24px 12px 24px 12px;
  border: 1px solid $text-gray-200;
  background: $white;
  box-shadow: 0px 2px 6px 4px rgba(16, 24, 40, 0.1);
  padding: 46px;

  &.card {
    .input-main {
      margin-bottom: 16px;
    }
  }

  h1 {
    color: $text-gray-900;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 12px;
  }

  p {
    color: $text-gray-500;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &.need_help {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text-gray-500-1;
      text-align: center;
      border-top: 1px solid $text-gray-300;
      margin-top: 18px;
      padding-top: 18px;
    }

    &.change_password{
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 767px) {
  .reset_password_card{
    padding: 24px;
  }
}
