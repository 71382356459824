.form-floating {
  margin-bottom: 2px;

  > .form-control-plaintext {
    &:focus,
    &:not(:placeholder-shown) {
      padding: 14px 16px;
    }
  }

  > .form-control {
    padding: 14px 16px;
    align-items: center;
    width: 100%;
    border-radius: 8px 16px 8px 16px;
    border: 1px solid $text-gray-300;
    min-height: auto;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $text-gray-900;

    &:focus,
    &:not(:placeholder-shown) {
      padding: 14px 16px;
    }

    &:focus,
    &:not(:placeholder-shown),
    .form-select {
      ~ label {
        transform: translateY(-1.4rem) translateX(0.3rem);
        color: $theme-purple-500;
        padding: 14px 12px;
      }
    }

    &:not(:focus) {
      ~ label {
        color: #5d667b;
      }
    }

    &:focus {
      box-shadow: none;
      border: 1px solid $theme-purple-500;
      border-radius: 8px 16px 8px 16px;
      opacity: 0.1px;
      box-shadow: 0px 0px 0px 3px $shadow-sm-input;
      padding: 14px 16px;
    }

    &:disabled {
      background-color: transparent;
      opacity: 1;
    }

    &:disabled ~ label::after,
    &:disabled ~ label::after {
      background-color: $white;
    }
  }

  > label {
    padding: 14px 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $theme-purple-500;
  }

  .left-img {
    position: absolute;
    left: 12px;
    top: 18px;
  }

  &.left-img-form-control {
    > .form-control {
      padding-left: 47px;
    }

    > label {
      margin-left: 30px;
    }
  }

  .edit_btn {
    color: $theme-purple-500;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    position: absolute;
    right: 12px;
    top: 15px;
    cursor: pointer;
  }

  .right-img {
    position: absolute;
    right: 12px;
    top: 17px;
    cursor: pointer;
  }

  &.right-img-form-control {
    > .form-control {
      padding-right: 45px !important;
    }
  }

  &.right-text-form-control {
    > .form-control {
      padding-right: 55px !important;
    }
  }

  &.input-error {
    > .form-control {
      border: 1px solid $error-red-500;
      border-radius: 8px 16px;
      box-shadow: 0px 0px 0px 3px $shadow-sm-error-input;

      &:focus,
      &:not(:placeholder-shown),
      .form-select {
        ~ label {
          color: $error-red-500;
        }
      }
    }
  }
}

.err-msg {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: $error-red-500;
}

//simple input

.input-field-container {
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1px solid $text-gray-300;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  color: $text-gray-500-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.input-main label.simple-label {
  display: block;
}

.simple-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  // margin-bottom: 6px;
}

.input-field:focus {
  outline: none;
}
