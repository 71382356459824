.custom-btn {
  padding: 10px 18px 10px 18px;
  border-radius: 8px 16px;
  border: 1px 0px 0px 0px;
  background: $theme-purple-500;
  //   background: linear-gradient(180deg, #8a75fe -0.01%, #3a58ef 100.01%);
  color: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-color: $theme-purple-500;

  &.btn {
    &:hover {
      color: $white;
      background-color: $theme-purple-500;
      border-color: $theme-purple-500;
    }
  }
}
